import { CurrentPoint } from "@/app/_components/GlobalStore/CurrentPoint"
import type { AccountSetting, AnnouncementBanner } from "@/app/_types"
import { Account } from "./Account"
import { Announcement } from "./Announcement"
import { AppLink } from "./AppLink"
import { CartItemsCount } from "./CartItemsCount"
import { DeviceMediaQuery } from "./DeviceMediaQuery"
import { UserAgent } from "./UserAgent"

interface Props {
  referrer: string | null
  userAgent: string | null
  account: AccountSetting | null
  announcementBanner: AnnouncementBanner | null
  children: React.ReactNode
}

export function GlobalStore({
  referrer,
  userAgent,
  account,
  announcementBanner,
  children
}: Props) {
  return (
    <>
      <UserAgent userAgent={userAgent} />
      <AppLink referrer={referrer} />
      <DeviceMediaQuery />
      <Account account={account} />
      <Announcement announcementBanner={announcementBanner} />
      <CurrentPoint />
      <CartItemsCount />
      {children}
    </>
  )
}
