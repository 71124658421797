"use client"

import { announcementBannerAtom } from "@/app/_store/atom"
import type { AnnouncementBanner } from "@/app/_types"
import { useHydrateAtoms } from "jotai/utils"

interface Props {
  announcementBanner: AnnouncementBanner | null
}

export function Announcement({ announcementBanner }: Props) {
  if (announcementBanner !== null) {
    // hydrationのときに一度だけ実行される
    useHydrateAtoms([[announcementBannerAtom, announcementBanner]])
  }

  return null
}
