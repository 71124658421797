"use client"

import { CSRF_TOKEN } from "@/app/_data/local_storage_key"
import { setStorageData } from "@/app/_utils/localStorage"
import { useEffect } from "react"

interface Props {
  csrfToken: string | null
}

export function LocalStorageSettings({ csrfToken }: Props) {
  useEffect(() => {
    if (csrfToken !== null) {
      setStorageData(CSRF_TOKEN, csrfToken)
    }
  }, [csrfToken])

  return null
}
