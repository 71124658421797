"use client"

import { currentPointAtom, userId as userIdAtom } from "@/app/_store/atom"
import { fetchWithCache } from "@/app/_utils/fetch"
import { useAtomValue, useSetAtom } from "jotai"
import { useCallback, useEffect } from "react"

export function CurrentPoint() {
  const setCurrentPoint = useSetAtom(currentPointAtom)
  const userId = useAtomValue(userIdAtom)

  const fetch = useCallback(async () => {
    if (userId === null) {
      return
    }
    const storageKey = `shopping_current_point_${userId}`
    const { point } = await fetchWithCache<{ point: number }>(
      "/v2/apis/screens/shopping/points/current_point",
      storageKey,
      1
    )
    setCurrentPoint(point)
  }, [userId, setCurrentPoint])

  useEffect(() => {
    // 表示する箇所が現状ないので一旦取得しないようにしている
    // TODO: 再度表示したい場合は↓のコメントアウトを外す
    // fetch()
  }, [])

  return null
}
