"use client"

import { NEWS_BADGE_KEY } from "@/app/_data/local_storage_key"
import { newsUnreadCountAtom } from "@/app/_store/atom"
import { account as accountAtom } from "@/app/_store/atom"
import { fetchWithCache } from "@/app/_utils/fetch"
import { useAtomValue, useSetAtom } from "jotai"
import { usePathname } from "next/navigation"
import { useCallback, useEffect, useRef } from "react"

export function FetchNewsMiddleware() {
  const pathname = usePathname()
  const prevPathname = useRef("")
  const setNewsUnreadCount = useSetAtom(newsUnreadCountAtom)
  const account = useAtomValue(accountAtom)

  const fetch = useCallback(async () => {
    // biome-ignore lint/complexity/noBannedTypes: APIがこうなっているので仕方ない
    const data = await fetchWithCache<{ body: { score: number } } | {}>(
      "/v2/apis/components/news/badge",
      NEWS_BADGE_KEY,
      1
    )

    setNewsUnreadCount("body" in data ? data.body.score : 0)
  }, [setNewsUnreadCount])

  // ページ移動時のみ発火。本来はmiddlewareで行いたいが、現状EdgeRuntimeでのみ利用できるので代替手段として実装。
  // 現状searchParamsの変化はキャッチしていない
  useEffect(() => {
    if (pathname === prevPathname.current || account === undefined) {
      return
    }

    // 未ログインなら未読数は常に0
    if (account === null) {
      setNewsUnreadCount(0)
      return
    }

    fetch()

    prevPathname.current = pathname
  }, [pathname, account, fetch, setNewsUnreadCount])

  return null
}
