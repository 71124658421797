"use client"

import { AnnouncementBanner } from "@/app/_components/AnnouncementBanner"
import { Footer } from "@/app/_components/Footer"
import { Header } from "@/app/_components/Header"
import { isAppUA } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { usePathname } from "next/navigation"

interface Props {
  children: React.ReactNode
}

const withoutHeaderPaths = [
  "/account/login",
  "/account/logout",
  "/account/signup"
]

const withoutFooterPaths = [
  "/shopping/carts",
  "/account/login",
  "/account/logout",
  "/account/signup"
]

export function HeaderAndFooter({ children }: Props) {
  const isApp = useAtomValue(isAppUA)
  const pathname = usePathname()

  const shouldHideHeader = withoutHeaderPaths.includes(pathname)
  const shouldHideFooter = withoutFooterPaths.includes(pathname)

  return (
    <>
      {!isApp && <AnnouncementBanner />}
      {!isApp && !shouldHideHeader && <Header />}
      {children}
      {!isApp && !shouldHideFooter && <Footer />}
    </>
  )
}
