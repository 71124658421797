"use client"

import { media, mediaType } from "@/app/_store/atom"
import { useSetAtom } from "jotai"
import { useCallback, useEffect } from "react"

// window.matchMedia()で用いる
const breakpoint = {
  mobile: "(max-width: 767px)",
  tablet: "(min-width: 768px) and (max-width: 959px)",
  desktop: "(min-width: 960px)"
}

export function DeviceMediaQuery() {
  const setMedia = useSetAtom(media)

  const handleChangeMedia = useCallback(() => {
    const isMobile = window.matchMedia(breakpoint.mobile).matches
    const isTablet = window.matchMedia(breakpoint.tablet).matches
    const isDesktop = window.matchMedia(breakpoint.desktop).matches

    const type = isDesktop
      ? mediaType.desktop
      : isTablet
        ? mediaType.tablet
        : isMobile
          ? mediaType.mobile
          : mediaType.desktop

    setMedia(type)
  }, [setMedia])

  const addMediaQueryListener = useCallback(
    (query: string, callback: () => void) => {
      const mql = window.matchMedia(query)
      if (mql.addEventListener) {
        mql.addEventListener("change", callback)
        // 古いversionのsafariでmatchMedia.addEventListenerがundefinedになるため代わりにaddListenerを追加
        // sentryの参考issue: https://roomclip.sentry.io/issues/4566932791/?project=5516509&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=7d&stream_index=6
        // addListenerは推奨されていないが(https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener)互換性を保つために追加(将来的には削除する)
      } else if (mql.addListener) {
        mql.addListener(callback)
      }
      callback() // 初期値の設定
    },
    []
  )

  const removeMediaQueryListener = useCallback(
    (query: string, callback: () => void) => {
      const mql = window.matchMedia(query)
      if (mql.removeEventListener) {
        mql.removeEventListener("change", callback)
        // 古いversionのsafariでmatchMedia.removeEventListenerがundefinedになるため代わりにremoveListenerを追加
      } else if (mql.removeListener) {
        mql.removeListener(callback)
      }
    },
    []
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: 1回しか発火してほしくないため
  useEffect(() => {
    addMediaQueryListener(breakpoint.mobile, handleChangeMedia)
    addMediaQueryListener(breakpoint.tablet, handleChangeMedia)
    addMediaQueryListener(breakpoint.desktop, handleChangeMedia)

    return () => {
      removeMediaQueryListener(breakpoint.mobile, handleChangeMedia)
      removeMediaQueryListener(breakpoint.tablet, handleChangeMedia)
      removeMediaQueryListener(breakpoint.desktop, handleChangeMedia)
    }
  }, [])

  return null
}
