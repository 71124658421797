import { CART_ITEMS_COUNT_KEY } from "@/app/_data/local_storage_key"
import { cartItemsCountAtom } from "@/app/_store/atom"
import { getStorageData, setStorageData } from "@/app/_utils/localStorage"
import { useSetAtom } from "jotai"
import { useCallback, useMemo } from "react"
import type ShopifyBuy from "shopify-buy"
import {
  createCheckoutIdKey,
  createShopifyClient,
  getCheckoutId,
  getParamsForEditCheckout,
  getShopifyCredential,
  removeCheckoutId,
  verifyCheckoutByCart
} from "./utils"

export function useRemoveLineItems(userId: number | null) {
  const getCartItemsCount = useGetCartItemsCount(userId)

  const removeFromCart = useCallback(
    async (lineItemIds: string[]) => {
      const { client, checkoutId } = await getParamsForEditCheckout(userId)
      const checkout = await client.checkout.removeLineItems(
        checkoutId,
        lineItemIds
      )

      // カートからアイテムを削除してカートが空になっていたらcheckoutを破棄
      if (checkout.lineItems.length === 0) {
        removeCheckoutId(userId)
      }

      // StoreとlocalStorageのカートの商品個数を更新
      getCartItemsCount(true)

      return checkout
    },
    [userId, getCartItemsCount]
  )

  return removeFromCart
}

export function useUpdateQuantity(userId: number | null) {
  const getCartItemsCount = useGetCartItemsCount(userId)

  const updateQuantity = useCallback(
    async (lineItemId: string, quantity: number) => {
      const { client, checkoutId } = await getParamsForEditCheckout(userId)

      const lineItemsToUpdate = [{ id: lineItemId, quantity }]
      const checkout = await client.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate
      )

      // StoreとlocalStorageのカートの商品個数を更新
      getCartItemsCount(true)

      return checkout
    },
    [userId, getCartItemsCount]
  )

  return updateQuantity
}

export function useAddVariantToCart(userId: number | null) {
  const getCartItemsCount = useGetCartItemsCount(userId)

  const addToCart = useCallback(
    async (variantId: string, quantity: number) => {
      const { client, checkoutId } = await getParamsForEditCheckout(userId)
      // shopify-buyの型が足りていないのでanyとunion
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const checkout: ShopifyBuy.Checkout & any =
        await client.checkout.addLineItems(checkoutId, [
          { variantId, quantity }
        ])
      if (checkout.errors !== undefined || checkout.userErrors.length > 0) {
        const errorMessage =
          checkout.errors !== undefined && checkout.errors[0] !== undefined
            ? checkout.errors[0].message
            : checkout.userErrors.message
        throw new Error(errorMessage)
      }

      // StoreとlocalStorageのカートの商品個数を更新
      getCartItemsCount(true)

      return checkout
    },
    [userId, getCartItemsCount]
  )

  return addToCart
}

export function useHasCheckoutId(userId: number | null) {
  const hasCheckoutId = useMemo(() => {
    const checkoutIdKey = createCheckoutIdKey(userId)
    const savedCheckoutId = localStorage.getItem(checkoutIdKey)
    return savedCheckoutId !== null
  }, [userId])

  return hasCheckoutId
}

export function useGetCarts(userId: number | null) {
  const getCarts = useCallback(async () => {
    const checkoutId = await getCheckoutId(userId, true)
    if (checkoutId === null) {
      // まだチェックアウトが存在していないなら空を返す
      return null
    }

    const { domain, accessToken } = getShopifyCredential()
    const client = createShopifyClient(domain, accessToken)

    const cart = await client.checkout.fetch(checkoutId)
    const valid = verifyCheckoutByCart(userId, cart)
    if (!valid) {
      return null
    }

    return cart.lineItems.length > 0 ? cart : null
  }, [userId])

  return getCarts
}

// カート内の商品数を取得する
export function useGetCartItemsCount(userId: number | null) {
  const getCarts = useGetCarts(userId)
  const updateCartItemsCount = useUpdateCartItemsCount(userId)

  const getCartItemsCount = useCallback(
    async (isForce = false) => {
      // localStorageにキャッシュデータがある かつ
      // ログイン中のユーザーIDと一致している 場合はキャッシュを返す
      if (!isForce) {
        const storageData = getStorageData<{ userId: number; count: number }>(
          CART_ITEMS_COUNT_KEY
        )
        if (storageData !== undefined && storageData.userId === userId) {
          return storageData.count
        }
      }

      const cart = await getCarts()

      let cartItemsCount = 0
      if (cart) {
        cartItemsCount = cart.lineItems.reduce(
          (acc, item) => acc + item.quantity,
          0
        )
      }

      // localStorageとStoreを更新する
      updateCartItemsCount(cartItemsCount)

      return cartItemsCount
    },
    [userId, getCarts, updateCartItemsCount]
  )

  return getCartItemsCount
}

// カート内の商品数を更新する
export function useUpdateCartItemsCount(userId: number | null) {
  const setItemsCount = useSetAtom(cartItemsCountAtom)

  const updateCartItemsCount = useCallback(
    async (count: number) => {
      // TODO: localStorage

      setItemsCount(count)

      // LocalStorageにカート内の商品数を保存(有効期限1分)
      setStorageData(CART_ITEMS_COUNT_KEY, { userId, count }, 1)
    },
    [userId, setItemsCount]
  )

  return updateCartItemsCount
}
