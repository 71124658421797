"use client"

import { userId as userIdAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import { useEffect } from "react"

export function GoogleAnalyticsSettings({ gaId }: { gaId: string }) {
  const userId = useAtomValue(userIdAtom)

  // biome-ignore lint/correctness/useExhaustiveDependencies: 1回しか発火してほしくないため
  useEffect(() => {
    const customParams =
      userId !== null
        ? {
            user_id: userId
          }
        : {}
    window.gtag("config", gaId, customParams)
  }, [])

  return null
}
