"use client"

import { LineClamp } from "@/app/_components/LineClamp"
import { announcementBannerAtom } from "@/app/_store/atom"
import { useAtomValue } from "jotai"
import * as styles from "./index.css"

export function AnnouncementBanner() {
  const announcementBanner = useAtomValue(announcementBannerAtom)

  if (announcementBanner === null || announcementBanner === undefined) {
    return null
  }

  return (
    <div className={styles.bannerContainer}>
      {announcementBanner.link !== null ? (
        <a className={styles.link} href={announcementBanner.link}>
          <div className={styles.announcementBanner}>
            <LineClamp text={announcementBanner.text} line={1} />
          </div>
        </a>
      ) : (
        <div className={styles.announcementBanner}>
          <LineClamp text={announcementBanner.text} line={1} />
        </div>
      )}
    </div>
  )
}
