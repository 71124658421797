"use client"

import { appLinkBaseUrlAtom } from "@/app/_store/atom"
import { useSetAtom } from "jotai"
import { usePathname } from "next/navigation"
import { stringify } from "qs"
import { useEffect, useMemo } from "react"
import { isMobile } from "react-device-detect"

interface Props {
  referrer: string | null
}

export function AppLink({ referrer }: Props) {
  const setAppLinkBaseUrl = useSetAtom(appLinkBaseUrlAtom)
  const pathName = usePathname()

  const queryParams = useMemo(
    () => ({
      device_category: isMobile ? "sp" : "pc",
      page: pathName,
      ref: referrer
    }),
    [pathName, referrer]
  )

  useEffect(() => {
    setAppLinkBaseUrl(
      `${process.env.NEXT_PUBLIC_APP_LINK_BASE_URL}${stringify(queryParams)}`
    )
  }, [setAppLinkBaseUrl, queryParams])

  return null
}
