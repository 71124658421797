"use client"

import type { AccountSetting, AnnouncementBanner } from "@/app/_types"
import { Provider } from "jotai"
import { useAtomsDebugValue } from "jotai-devtools/utils"
import { GlobalStore } from "./GlobalStore"

const DebugAtoms = () => {
  // localでのみ有効
  useAtomsDebugValue()
  return null
}

interface Props {
  referrer: string | null
  userAgent: string | null
  account: AccountSetting | null
  announcementBanner: AnnouncementBanner | null
  children: React.ReactNode
}

export function GlobalStoreProvider({
  referrer,
  userAgent,
  children,
  account,
  announcementBanner
}: Props) {
  return (
    <Provider>
      <DebugAtoms />
      <GlobalStore
        referrer={referrer}
        userAgent={userAgent}
        account={account}
        announcementBanner={announcementBanner}
      >
        {children}
      </GlobalStore>
    </Provider>
  )
}
