"use client"

import { account as accountOnAtom } from "@/app/_store/atom"
import type { AccountSetting } from "@/app/_types"
import { useHydrateAtoms } from "jotai/utils"

interface Props {
  account: AccountSetting | null
}

export function Account({ account }: Props) {
  // hydrationのときに一度だけ実行される
  useHydrateAtoms([[accountOnAtom, account]])

  return null
}
