"use client"

import { useGetCartItemsCount } from "@/app/_hooks/shopify"
import { migrateDataForShopifyBuyV2toV3 } from "@/app/_hooks/shopify/compatibility"
import { cartItemsCountAtom, userId as userIdAtom } from "@/app/_store/atom"
import { useAtomValue, useSetAtom } from "jotai"
import { usePathname } from "next/navigation"
import { useCallback, useEffect } from "react"

export function CartItemsCount() {
  const setCount = useSetAtom(cartItemsCountAtom)
  const userId = useAtomValue(userIdAtom)
  const getCartItemsCount = useGetCartItemsCount(userId)
  const pathname = usePathname()

  const fetch = useCallback(async () => {
    // V2からV3へカートのデータを移行するのでカート内の商品数を取得する前に行う
    // カートページだけカート取得直前にデータ移行を行いたいのでここでは行わない
    if (pathname !== "/shopping/carts") {
      await migrateDataForShopifyBuyV2toV3(userId)
    }

    const count = await getCartItemsCount()
    setCount(count)
  }, [setCount, getCartItemsCount, userId, pathname])

  // biome-ignore lint/correctness/useExhaustiveDependencies: 1回しか発火してほしくないため
  useEffect(() => {
    fetch()
  }, [])

  return null
}
