import type { ShopifyShopCredential } from "@/app/_types/shopify"
import ShopifyBuy from "shopify-buy"

const shopifyParam = {
  domain: process.env.NEXT_PUBLIC_SHOPIFY_CONFIG_DOMAIN,
  accessToken: process.env.NEXT_PUBLIC_SHOPIFY_CONFIG_ACCESS_TOKEN
} as ShopifyShopCredential

export function getShopifyCredential(): ShopifyShopCredential {
  return shopifyParam
}

export function createShopifyClient(
  domain: string,
  storefrontAccessToken: string
): ShopifyBuy {
  const client = ShopifyBuy.buildClient({
    domain,
    storefrontAccessToken,
    language: "ja-JP",
    apiVersion: "2023-10"
  })

  return client
}

export async function getCheckoutId(userId: number | null): Promise<string>

export async function getCheckoutId(
  userId: number | null,
  disabledCreate: true
): Promise<string | null>

export async function getCheckoutId(
  userId: number | null,
  disabledCreate = false
) {
  const { domain, accessToken } = getShopifyCredential()

  // ログイン時：checkoutId_[userId]があればそのまま利用
  // 未ログイン時：checkoutId_not_loginがあればそのまま利用、そうでなければ作成し同名で保存
  const checkoutIdKey = createCheckoutIdKey(userId)
  const savedCheckoutId = localStorage.getItem(checkoutIdKey)
  if (savedCheckoutId !== null) {
    // 今ログイン中で、ログイン中のカートが存在していて、未ログイン時のカートが存在していたら不要なので破棄する
    if (userId !== null) {
      const notLoginKey = createCheckoutIdKey(null)
      const notLoginSavedCheckoutId = localStorage.getItem(notLoginKey)

      if (notLoginSavedCheckoutId !== null) {
        removeCheckoutId(null)
      }
    }

    // 今存在しているカートのIDを返す
    return savedCheckoutId
  }

  // ログイン時：checkoutId_not_loginがあればcheckoutId_[userId]に保存しトラッキングAPIを叩く
  if (userId !== null) {
    const notLoginKey = createCheckoutIdKey(null)
    const notLoginSavedCheckoutId = localStorage.getItem(notLoginKey)

    if (notLoginSavedCheckoutId !== null) {
      // 未ログイン時に保存していたチェックアウトIDを引き継ぎcheckout_[userId]として保存
      removeCheckoutId(null)
      setCheckoutId(userId, notLoginSavedCheckoutId)

      // TODO: 取得したcheckoutIdをトラッキング用にイベントを送る

      return notLoginSavedCheckoutId
    }
  }

  if (disabledCreate) {
    return null
  }

  // 未ログイン：チェックアウトを作成
  // ログイン時：チェックアウトを作成しトラッキング用にAPIを叩く
  const client = createShopifyClient(domain, accessToken)
  const { id: checkoutId } = await client.checkout.create()
  setCheckoutId(userId, checkoutId)

  if (userId !== null) {
    // TODO: 取得したcheckoutIdをトラッキング用にイベントを送る
  }

  return `${checkoutId}`
}

export function setCheckoutId(
  userId: number | null,
  checkoutId: string | number
) {
  localStorage.setItem(createCheckoutIdKey(userId), `${checkoutId}`)
}

export function removeCheckoutId(userId: number | null) {
  localStorage.removeItem(createCheckoutIdKey(userId))
}

export function createCheckoutIdKey(userId: number | null) {
  const userIdSuffix = userId !== null ? `${userId}` : "not_login"
  return `checkoutId_${userIdSuffix}`
}

// カートが終了しているかどうかをチェックする
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function verifyCheckoutByCart(userId: number | null, cart: any) {
  if (cart == null) {
    // カートが終了していたら保存しているCheckoutIdを削除する
    removeCheckoutId(userId)
    return false
  }
  return true
}

// カートを操作するためのcheckoutIdとclientを返す
export async function getParamsForEditCheckout(userId: number | null) {
  let checkoutId = await getCheckoutId(userId)
  const { domain, accessToken } = getShopifyCredential()
  let client = createShopifyClient(domain, accessToken)

  // 仮に終了しているCheckoutを使い続けていた場合はCheckoutを破棄し再作成する
  const cart = await client.checkout.fetch(checkoutId)
  const valid = verifyCheckoutByCart(userId, cart)
  if (!valid) {
    checkoutId = await getCheckoutId(userId)
    client = createShopifyClient(domain, accessToken)
  }

  return { checkoutId, client }
}
