"use client"

import { isAppUA } from "@/app/_store/atom"
import { useHydrateAtoms } from "jotai/utils"

interface Props {
  userAgent: string | null
}

export function UserAgent({ userAgent }: Props) {
  const ua = userAgent?.toLowerCase() ?? ""
  const isApp =
    ua.includes("roomclip") ||
    ua.includes("dreamroom") ||
    ua.includes("apache-httpclient")

  useHydrateAtoms([[isAppUA, isApp]])

  return null
}
